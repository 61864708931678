@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  width: 40vmin;
  pointer-events: none;
}

.bissap{
  width: 47vmin;
  height: 230px;
}
.twoimages{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 10px;

}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-header {
  background-color: rgb(250, 250, 250);
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  
  font-style: normal;

  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.topp{

    padding: 45px 0;
    background-color: #fff;
}
.menu{

    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;

}

.menu:focus{

    color: red
}
.portfollio{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 10px 10px 10px;
}